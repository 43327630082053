(function () {
    'use strict';

    angular.module('aerosApp')
        .controller('ToolLibrariesController', ToolLibrariesController);

    ToolLibrariesController.$inject = ['$state', 'toolLibraries', 'toolsDefinition', 'ToolLibFactory', 'appDispatcher',
        'userInfo', 'ToolService', 'toolPresets', 'AFL_GRID'];

    function ToolLibrariesController($state, toolLibraries, toolsDefinition, ToolLibFactory, appDispatcher, userInfo,
                                     ToolService, toolPresets, AFL_GRID) {
        var toolType = $state.params.toolType,
            toolLibFactory = ToolLibFactory(toolType),
            vm = this;

        vm.rows = toolLibraries;
        vm.cols = toolsDefinition[toolType] && toolsDefinition[toolType].groups || [];
        vm.reload = reload;
        vm.toolType = toolType;
        vm.widths = AFL_GRID.widths[toolType];
        vm.userInfo = userInfo;
        vm.license = userInfo.organization.license;
        $state.license = vm.license;

        filterFeatures();

        ToolService.presetMatch(ToolService, toolsDefinition, toolLibraries,toolPresets) ;

        function reload() {
            toolLibFactory.query({orgId: $state.params.id}).$promise.then(function (tlibs) {
                vm.rows = tlibs;
                ToolService.presetMatch(ToolService, toolsDefinition, tlibs,toolPresets) ;
            }).catch(Notification.error);
        }

        appDispatcher.on('toolLibListUpdate', function (e) {
            reload();
        });

        function filterFeatures() {
            var features = [],
                indexesToRemove = [];

            userInfo.organization.features.forEach(function (feature) {
                features.push(feature.name.toLowerCase())
            });

            vm.rows.forEach(function (row, index) {
                if (features.indexOf(row.toolType.toLowerCase()) === -1) {
                    indexesToRemove.push(index)
                }
            });

            for (var i = indexesToRemove.length - 1; i >= 0; i--) {
                vm.rows.splice(indexesToRemove[i], 1);
            }
        }
    }

}());
